<template>
  <div class="transaction-status-button">
    <b-dropdown
      :disabled="loading"
      :variant="buttonVariant"
      right
    >
      <template #button-content>
        <b-spinner
          v-if="loading"
          small
          label="Updating"
        />
        {{ text }}
      </template>

      <b-dropdown-item
        v-for="option in processedOptions"
        :key="option.value"
        @click="selectOption(option)"
      >
        {{ option.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  TRANSACTION_STATUS_CANCELED,
  TRANSACTION_STATUS_FAILED,
  TRANSACTION_STATUS_PAID,
  TRANSACTION_STATUS_WAIT_APPROVAL,
  TRANSACTION_STATUS_WAIT_PAYMENT
} from '../constant'

export default {
  name: 'TransactionStatus',

  props: {
    status: {
      type: Number,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    options () {
      return [
        {
          text: 'Wait for payment',
          value: TRANSACTION_STATUS_WAIT_PAYMENT
        },
        {
          text: 'Wait for approval',
          value: TRANSACTION_STATUS_WAIT_APPROVAL
        },
        {
          text: 'Paid',
          value: TRANSACTION_STATUS_PAID
        },
        {
          text: 'Failed',
          value: TRANSACTION_STATUS_FAILED
        },
        {
          text: 'Canceled',
          value: TRANSACTION_STATUS_CANCELED
        }
      ]
    },

    processedOptions () {
      return this.options.filter(option => option.value !== this.status)
    },

    text () {
      const option = this.options.find(option => option.value === this.status)
      return option ? option.text : 'Unknown'
    },

    buttonVariant () {
      switch (this.status) {
        case TRANSACTION_STATUS_WAIT_PAYMENT:
          return 'dark'
        case TRANSACTION_STATUS_WAIT_APPROVAL:
          return 'warning'
        case TRANSACTION_STATUS_PAID:
          return 'success'
        case TRANSACTION_STATUS_CANCELED:
          return 'secondary'
        case TRANSACTION_STATUS_FAILED:
          return 'secondary'
      }
      return this.status
    }
  },

  methods: {
    selectOption (option) {
      this.$emit('change', option)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
