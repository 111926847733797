<template>
  <div v-if="invoice" class="invoice-edit-page">
    <b-card
      header-bg-variant="white"
      header-class="bg-white d-flex justify-content-between align-items-center py-3"
      class="mb-3"
    >
      <template #header>
        <h5 class="mb-0">
          Invoice: 
          <span class="font-weight-500">{{ invoice.order_id }}</span>
        </h5>

        <div class="h5 mb-0">
          <transaction-status-button
            :status="invoice.status"
            @change="onChangeStatus"
            :loading="statusUpdating"
          />
        </div>
      </template>

      <b-row class="text-center">
        <b-col md="3">
          <div class="small text-muted">Donor</div>
          <div class="mt-2">
              {{ invoice.customer_first_name }} {{ invoice.customer_last_name}}
              <div class="small text-muted">{{ invoice.customer_email }}</div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="small text-muted">Payment Method</div>
          <div class="mt-2">
            <payment-method :method="invoice.payment_method" />
          </div>
        </b-col>

        <b-col md="3">
          <div class="small text-muted">{{ isPaid ? 'Paid At' : 'Updated At' }}</div>
          <div class="mt-2">
            <date-time :date="new Date(isPaid ? invoice.payment_timestamp : invoice.updated_at)" />
          </div>
        </b-col>

        <b-col md="3">
          <div class="small text-muted">Amount</div>
          <div class="mt-2">
            <number :amount="invoice.price" prepend-text="฿" />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Donor Information -->
    <b-row>
      <b-col>
        <b-card
          header-class="bg-white"
          class="mb-3"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              <h6 class="mb-0 text-muted py-2">Donor Information</h6>
              <!-- <b-button variant="outline-primary" size="sm">Edit</b-button> -->
            </div>
          </template>

          <div class="mb-2">
            <div class="small text-muted">Name</div>
            <div>{{ invoice.customer_first_name }} {{ invoice.customer_last_name }}</div>
          </div>

          <div class="mb-2">
            <div class="small text-muted">Email</div>
            <div>{{ invoice.customer_email }}</div>
          </div>

          <div class="mb-2">
            <div class="small text-muted">Email Consent</div>
            <div>
              <b-icon-check
                v-if="invoice.customer_email_consent"
                color="green"
                font-scale="2"
              />
              <b-icon-x
                v-else
                color="red"
                font-scale="2"
              />
            </div>
          </div>

          <div class="mb-2">
            <div class="small text-muted">Phone Number</div>
            <div>{{ invoice.customer_phone }}</div>
          </div>

          <div class="">
            <div class="small text-muted">Phone Consent</div>
            <div>
              <b-icon-check
                v-if="invoice.customer_phone_consent"
                color="green"
                font-scale="2"
              />
              <b-icon-x
                v-else
                color="red"
                font-scale="2"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col>
        <b-card
          header-class="bg-white"
          class="mb-3"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              <h6 class="mb-0 text-muted py-2">Receipt Information</h6>
              <!-- <b-button variant="outline-primary" size="sm">Edit</b-button> -->
            </div>
          </template>

          <!-- Name -->
          <div
            v-if="invoice.receipt_corporate_name"
            class="mb-2"
          >
            <div class="small text-muted">Corporate Name</div>
            <div>{{ invoice.receipt_corporate_name }}</div>
          </div>
          <div
            v-else
            class="mb-2"
          >
            <div class="small text-muted">Name</div>
            <div>{{ invoice.receipt_first_name }} {{ invoice.receipt_last_name }}</div>
          </div>

          <!-- Tax ID -->
          <div
            v-if="invoice.receipt_corporate_tax_id"
            class="mb-2"
          >
            <div class="small text-muted">Corporate Tax ID</div>
            <div>{{ invoice.receipt_corporate_tax_id }}</div>
          </div>

          <!-- Request Hard Copy -->
          <div class="mb-2">
            <div class="small text-muted">Request Hard Copy</div>
            <div>
              <b-icon-check
                v-if="invoice.receipt_hard_copy"
                color="green"
                font-scale="2"
              />
              <b-icon-x
                v-else
                color="red"
                font-scale="2"
              />
            </div>
          </div>

          <!-- Shipping Address -->
          <div
            v-if="invoice.receipt_hard_copy"
          >
            <div class="small text-muted">Shipping Address</div>
            <div>{{ invoice.receipt_shipping_address1 }}</div>
            <div>{{ invoice.receipt_shipping_address2 }}</div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Line Items -->
    <b-card
      header-class="bg-white"
      body-class="px-0 py-0"
      class="mb-3"
    >
      <template #header>
        <h6 class="mb-0 text-muted py-2">Item List</h6>
      </template>

      <table class="table table-invoice-item-list">
        <thead>
          <tr>
            <th>Description</th>
            <th width="100" class="text-right">QTY</th>
            <th width="150" class="text-right">Unit Price</th>
            <th width="150" class="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in invoice.items"
            :key="item.id"
          >
            <td>
              {{ item.item[`name_${currentLocale}`] }}
            </td>
            <td width="100" class="text-right">
              <number :amount="item.quantity" />
            </td>
            <td width="150" class="text-right">
              <number :amount="item.price" prepend-text="฿" />
            </td>
            <td width="150" class="text-right">
              <number :amount="item.total_price" prepend-text="฿" />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr style="border-top: 2px solid #111;">
            <td colspan="2">&nbsp;</td>
            <th width="150" class="text-right">Grand Total</th>
            <td width="150" class="text-right" style="border-bottom: 2px solid #111;">
              <number :amount="invoice.price" prepend-text="฿" />
            </td>
          </tr>
        </tfoot>
      </table>
    </b-card>

    <!-- Payment Transactions -->
    <b-card
      header-class="bg-white"
      body-class="px-0 py-0"
      class="mb-3"
    >
      <template #header>
        <h6 class="mb-0 text-muted py-2">Payment Transactions</h6>
      </template>

      <table class="table table-invoice-item-list">
        <thead>
          <tr>
            <th>Created</th>
            <th class="text-right">Amount</th>
            <th>Channel</th>
            <th>Tx ID</th>
            <th>Tx Timestamp</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tx in invoice.transactions"
            :key="tx.id"
          >
            <td>
              <date-time :date="new Date(tx.created_at)" />
            </td>
            <td class="text-right">
              <number :amount="tx.amount" prepend-text="฿" />
            </td>
            <td>{{ tx.payment_method }}</td>
            <td>{{ tx.transaction_id }}</td>
            <td>
              <date-time
                v-if="tx.payment_timestamp"
                :date="new Date(tx.payment_timestamp)"
              />
            </td>
            <td>
              <transaction-status :status="tx.status" />
            </td>
          </tr>

          <tr
            v-if="invoice.transactions.length === 0"
            class="text-center"
          >
            <td colspan="6" class="text-muted">No logs found</td>
          </tr>
        </tbody>
      </table>
    </b-card>

    <!-- Transfer Requests -->
    <b-card
      v-if="isPaymentMethodBankTransfer"
      header-class="bg-white"
      class="mb-3"
    >
      <template #header>
        <h6 class="mb-0 text-muted py-2">Bank Transfer Receipts</h6>
      </template>

      <template
        v-for="transferRequest in invoice.transfer_requests"
      >
        <div
          :key="transferRequest.id"
          class="row mb-2"
        >
            <div class="col-md-4">
              <a
                :href="getImageUrl(transferRequest.image)"
                target="_blank"
              >
                <img
                  :src="getImageUrl(transferRequest.image)"
                  class="img-fluid"
                >
              </a>
            </div>
            <div class="col-md-8">
                <div class="h6 text-muted mb-0 small">Amount: </div>
                <div class="mb-2">{{ transferRequest.amount }}</div>

                <div class="h6 text-muted mb-0 small">Uploaded At: </div>
                <div class="mb-2">{{ transferRequest.created_at }}</div>

                <div class="h6 text-muted mb-0 small">Note: </div>
                <div>{{ transferRequest.note }}</div>
            </div>
        </div>
        <hr :key="`sep_${transferRequest.id}`">
      </template>

      <div
        v-if="invoice.transfer_requests.length === 0"
        class="text-center text-muted"
      >
          No slips found.
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BIconCheck,
  BIconX
} from 'bootstrap-vue'
import DateTime from '../components/DateTime.vue'
import Number from '../components/Number.vue'
import PaymentMethod from '../components/PaymentMethod.vue'
import TransactionStatusButton from '../components/TransactionStatusButton.vue'
import {
  PAYMENT_METHOD_BANK_TRANSFER,
  TRANSACTION_STATUS_PAID
} from '../constant'

export default {
  name: 'InvoiceEdit',

  components: {
    BIconCheck,
    BIconX,
    DateTime,
    Number,
    PaymentMethod,
    TransactionStatusButton
  },

  computed: {
    invoiceId () {
      return this.$route.params.id
    },

    isPaid () {
      return this.invoice.status === TRANSACTION_STATUS_PAID
    },

    isPaymentMethodBankTransfer () {
      return this.invoice.payment_method === PAYMENT_METHOD_BANK_TRANSFER
    }
  },

  data () {
    return {
      invoice: null,
      statusUpdating: false
    }
  },

  created () {
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$invoices.get(this.invoiceId)
        this.invoice = resp.data.data
      }
      catch (e) {
        console.error(e.message)
      }
    },

    getImageUrl (path) {
      return `${process.env.VUE_APP_MEDIA_URL}/${path}`
    },

    async onChangeStatus (status) {
      const title = `Change Invoice Status?`
      const msg = `You are about to change status to ${status.text}. Do you want to continue?`
      const result = await this.$bvModal.msgBoxConfirm(msg, {
        title,
        okTitle: `Change to ${status.text}`,
        cancelTitle: 'No, I change my mind'
      })

      if (result) {
        this.statusUpdating = true
        
        try {
          const body = { status: status.value }
          await this.$invoices.updateStatus(this.invoice.id, body)
          await this.fetch()
        }
        catch (e) {
          alert(e.message)
        }
        finally {
          this.statusUpdating = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
